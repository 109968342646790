import moment from "moment";
import { useAuth } from "../../../contexts/auth";
export const ClientInfosModal = ({ props }) => {
  const { clientInfos } = props;
  const { user } = useAuth();
  const {
    nom,
    prenom,
    email,
    telephone,
    codepostal,
    adress,
    title,
    titre,
    sujet,
    data,
    message,
    created_at,
    time,
    aide,
    phase,
    montant,
    structure,
    numero_secu,
    password,
    file,
    files,
    userId,
  } = clientInfos;

  const findAdmin = user.otherAdmins.filter((item) => item._id == userId);
  return (
    <div className="modalViewClientInfos">
      {nom && (
        <p>
          <b>Nom :</b> {nom}
        </p>
      )}
      {findAdmin && findAdmin.length > 0 && (
        <p>
          <b>Agent :</b> {findAdmin[0].nom + " " + findAdmin[0].prenom}
        </p>
      )}
      {findAdmin && findAdmin.length == 0 && (
        <p>
          <b>Agent :</b> Vous
        </p>
      )}
      {prenom && (
        <p>
          <b>Prenom :</b> {prenom}
        </p>
      )}
      {email && (
        <p>
          <b>Email :</b> {email}
        </p>
      )}
      {telephone && (
        <p>
          <b>Telepone :</b> {telephone}
        </p>
      )}
      {codepostal && (
        <p>
          <b>Code postal :</b> {codepostal}
        </p>
      )}
      {adress && (
        <p>
          <b>Adresse :</b> {adress}
        </p>
      )}

      {titre && (
        <p>
          <b>Titre :</b> {titre}
        </p>
      )}
      {sujet && (
        <p>
          <b>Sujet :</b> {sujet}
        </p>
      )}
      {created_at && (
        <p>
          <b>Date de creation:</b> {moment(created_at).format("DD/MM/YYYY")}
        </p>
      )}

      {/* {time && (
        <p>
          <b>Heure :</b> {time}
        </p>
      )} */}
      {data && (
        <p>
          <b>Message :</b> {data}
        </p>
      )}
      {message && (
        <p>
          <b>Message :</b>
          <div dangerouslySetInnerHTML={{ __html: message }}></div>
        </p>
      )}
      {montant && (
        <p>
          <b>Montant :</b> {montant}
        </p>
      )}
      {aide && (
        <p>
          <b>Aide :</b> {aide}
        </p>
      )}
      {phase && (
        <p>
          <b>Phase :</b> {phase}
        </p>
      )}
      {structure && (
        <p>
          <b>Structure :</b> {structure}
        </p>
      )}
      {numero_secu && (
        <p>
          <b>Identifiant :</b> {numero_secu}
        </p>
      )}
      {password && password.length <= 20 && (
        <p>
          <b>Mot de passe du compte :</b> {password}
        </p>
      )}
      <section>
        {(file && file.length > 0) || (files && files.length > 0) ? (
          <>
            <b>Fichiers :</b>
            {(() => {
              if (files && files.length > 0) {
                return files.map((docf, idf) => (
                  <a href={docf} target="_blank" key={"sub Fi" + idf}>
                    Voir fichier {idf + 1}
                  </a>
                ));
              } else {
                if (Array.isArray(file)) {
                  return file.map((docf, idf) => (
                    <a href={docf} target="_blank" key={"sub Fi" + idf}>
                      Voir fichier {idf + 1}
                    </a>
                  ));
                } else {
                  return (
                    <a href={file} target="_blank">
                      {" "}
                      Voir le fichier
                    </a>
                  );
                }
              }
            })()}
          </>
        ) : null}
      </section>
    </div>
  );
};
