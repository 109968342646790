import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { errorToast, successToast } from "../../utils/alerts";
import { useModal } from "../../contexts/modal";
export const statusListWithoutLl = [
  { label: "Raccroché" },
  { label: "Déjà souscrit" },
  { label: "Faux numero" },
  { label: "Intéressé- RDV" },
  { label: "NRP occupé-à rappeler" },
  { label: "Pas éligible" },
  { label: "Pas intéressé-raccroché" },
  { label: "Reçoit déjà" },
  { label: "Rappel" },
  { label: "Souscrit" },
  { label: "Veut réfléchir" },
  { label: "SEPA" },
];

export const statusListWithOnlyLl = [{ label: "Lead Libre" }];

export const statusList = [...statusListWithOnlyLl, ...statusListWithoutLl];

function UpdateSimulationStatus({ simulation, refetchSimulations }) {
  const { hideModal } = useModal();
  const [currentSimStatus, setCurrentSimStatus] = useState("");

  useEffect(() => {
    setCurrentSimStatus(
      simulation.clientStatus ? simulation.clientStatus : statusList[0]
    );
  }, [simulation]);

  function updateStatus(newStatus) {
    axios
      .put("/admin/simulation/" + simulation._id + "/status", {
        status: newStatus,
      })
      .then((res) => {
        successToast("Statut modifier avec succes");
      })
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        const scrollIntoSimulation = () => {
          const treatedSim = document.getElementById(
            "simulation-" + simulation._id
          );
          if (treatedSim) {
            treatedSim.scrollIntoView();
          }
        };
        refetchSimulations(scrollIntoSimulation);
        setCurrentSimStatus(newStatus);
        hideModal();
      });
  }
  return (
    <div className="updateSimulationStatus">
      <h2>Qualification du prospect</h2>
      <section className="uss-statusTypes">
        {statusList.map((s, i) => (
          <span
            key={"simulation status nb" + i}
            className={s.label === currentSimStatus ? "isActive" : ""}
            onClick={() => updateStatus(s.label)}
          >
            {s.label}
          </span>
        ))}
      </section>
    </div>
  );
}

export default UpdateSimulationStatus;
