import { Link, NavLink } from "react-router-dom";
import { PhDotsNineBold, PhDoorBold } from "../Icons";
import {
  adminAndAgent,
  navForAdmin,
  navForAll,
  navForAdminAndAgent,
  navForAdminAgentAndCommercial,
  navForBlogerUser,
  adminAgentAndCommercial,
} from "../../Rawdata/links";
import { SimpleImage } from "../Img";
import { useAuth } from "../../contexts/auth";

export const NotConnectedNavbar = () => {
  return (
    <nav className="nav">
      <section className="nav-logo">
        <SimpleImage
          props={{ src: "logos/full.png", alt: "Logo Menu", className: "" }}
        />
      </section>
      <section className="nav-menu">
        <i className="mdi mdi-menu"></i>
      </section>

      <section className="nav-auth">
        <Link to="">Nous contacter</Link>
      </section>
      <aside></aside>
    </nav>
  );
};

export function toggleNavSidebar() {
  if (document.querySelector(".navSideBar") && document.querySelector("main")) {
    document.querySelector(".navSideBar").classList.toggle("hideNavSideBar");
  }
}

export function closeNavSidebar() {
  if (document.querySelector(".navSideBar") && document.querySelector("main")) {
    document.querySelector(".navSideBar").classList.add("hideNavSideBar");
  }
}

export const ConnectedNavbar = () => {
  const { user, logout } = useAuth();
  const userConnected = user;

  function disconnectUser() {
    logout();
  }

  return (
    <>
      <aside className="navSideBar">
        <section className="ns-links">
          <NavLink
            to={navForAll[0].link}
            className={({ isActive }) =>
              "flex ai-center " + (isActive ? "siseBarActiveLink" : "")
            }
          >
            {navForAll[0].icon}
            {navForAll[0].label}
          </NavLink>
          {adminAgentAndCommercial.includes(userConnected.grade) && (
            <>
              {navForAdminAgentAndCommercial.map((nfau, ind) => (
                <NavLink
                  key={"laan admin agent and com" + ind}
                  to={nfau.link}
                  className={({ isActive }) =>
                    "flex ai-center " + (isActive ? "siseBarActiveLink" : "")
                  }
                >
                  {nfau.icon}
                  {nfau.label}
                </NavLink>
              ))}
            </>
          )}
          {adminAndAgent.includes(userConnected.grade) && (
            <>
              {navForAdminAndAgent.map((nfau, ind) => (
                <NavLink
                  key={"laan " + ind}
                  to={nfau.link}
                  className={({ isActive }) =>
                    "flex ai-center " + (isActive ? "siseBarActiveLink" : "")
                  }
                >
                  {nfau.icon}
                  {nfau.label}
                </NavLink>
              ))}
            </>
          )}
          {userConnected.grade == "Bloger" && (
            <>
              {navForBlogerUser.map((nfau, ind) => (
                <NavLink
                  key={"labun " + ind}
                  to={nfau.link}
                  className={({ isActive }) =>
                    "flex ai-center " + (isActive ? "siseBarActiveLink" : "")
                  }
                >
                  {nfau.icon}
                  {nfau.label}
                </NavLink>
              ))}
            </>
          )}
          {userConnected.grade == "Admin" && (
            <>
              {navForAdmin.map((laan, ind) => (
                <NavLink
                  key={"laan two " + ind}
                  to={laan.link}
                  className={({ isActive }) =>
                    "flex ai-center " + (isActive ? "siseBarActiveLink" : "")
                  }
                >
                  {laan.icon}
                  {laan.label}
                </NavLink>
              ))}
              <NavLink
                to={navForBlogerUser[2].link}
                className={({ isActive }) =>
                  "flex ai-center " + (isActive ? "siseBarActiveLink" : "")
                }
              >
                {navForBlogerUser[2].icon}
                {navForBlogerUser[2].label}
              </NavLink>
            </>
          )}
          <NavLink
            to={navForAll[1].link}
            className={({ isActive }) =>
              "flex ai-center " + (isActive ? "siseBarActiveLink" : "")
            }
          >
            {navForAll[1].icon}
            {navForAll[1].label}
          </NavLink>
        </section>
      </aside>
      <nav className="navDash">
        <section className="nd-menu">
          <PhDotsNineBold
            onClick={() => toggleNavSidebar()}
            style={{ fontSize: "30px" }}
          />
        </section>
        <section className="nd-logo">
          <SimpleImage
            props={{ src: "logo.png", alt: "Logo Menu", className: "" }}
          />
        </section>

        <section className="nd-logout" onClick={() => disconnectUser()}>
          <span>{userConnected.nom + " " + userConnected.prenom} </span>
          <PhDoorBold />
        </section>
      </nav>
    </>
  );
};
