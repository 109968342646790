import Accueil from "./Routes/Accueil";
import { Routes, Route } from "react-router-dom";

import "./Assets/styles/utilClass.css";
import "./Assets/styles/otherTool.css";

import Dashboard from "./Routes/Dashboard";
import Notification from "./Routes/Notification";
import { useLocation } from "react-router";
import Client from "./Routes/Client";
import axios from "axios";

import Administration from "./Routes/Administration";
import Profil from "./Routes/Profil";
import { Modal } from "./GlobalComponents/Modal";
import Aide from "./Routes/Aide";
import moment from "moment";
import "moment/locale/fr";
import Simulations from "./Routes/Simulations";
import Prelevements from "./Routes/Prelevements";
import Contacts from "./Routes/Contacts";
import Avis from "./Routes/Avis";
import Blog from "./Routes/Blog";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TrustpilotReview from "./Routes/TrustpilotReview";
import MailEdit from "./Routes/MailEdit";
import "./Assets/styles/index.scss";
import { useEffect } from "react";
import { closeNavSidebar } from "./GlobalComponents/Navigation/Navbar";
import Reference from "./Routes/Reference";
moment.locale("fr");

function App() {
  axios.defaults.baseURL =
    process.env.NODE_ENV == "production"
      ? window.location.origin
      : "http://localhost:5110";
  const location = useLocation();

  const routesTab = [
    { path: "/", components: <Accueil />, hasNav: true },
    { path: "/Dashboard", components: <Dashboard />, hasNav: true },
    {
      path: "/Profil",
      components: <Profil />,
      hasNav: false,
    },
    {
      path: "/Client/:clientId/:clientMenu",
      components: <Client />,
      hasNav: false,
    },
    { path: "/Client/:clientAction", components: <Client />, hasNav: true },
    { path: "/Aides", components: <Aide />, hasNav: true },
    { path: "/Simulations", components: <Simulations />, hasNav: true },
    {
      path: "/sims/:status",
      components: <Simulations key={new Date()} />,
      hasNav: true,
    },

    { path: "/Notifications", components: <Notification />, hasNav: true },
    { path: "/Administration", components: <Administration />, hasNav: true },

    { path: "/Prelevements", components: <Prelevements />, hasNav: true },
    { path: "/Contacts", components: <Contacts />, hasNav: true },
    {
      path: "/Avis-trustpilot",
      components: <TrustpilotReview />,
      hasNav: true,
    },
    { path: "/Emails-edition", components: <MailEdit />, hasNav: true },

    { path: "/Blog/category", components: <Blog />, hasNav: true },
    { path: "/Blog/article/:action", components: <Blog />, hasNav: true },
    {
      path: "/Blog/article/:action/:actionId",
      components: <Blog />,
      hasNav: true,
    },

    { path: "/reference/", components: <Reference />, hasNav: true },
  ];

  useEffect(() => {
    if (window.innerWidth > 758) {
    } else {
      closeNavSidebar();
    }
  }, [location.pathname]);

  return (
    <>
      <main className={location.pathname == "/" ? "authPage" : ""}>
        <Routes>
          {routesTab.map((route, index) => (
            <Route
              key={"route nb" + index}
              path={route.path}
              element={route.components}
            />
          ))}
          <Route
            path="*"
            element={<p>Erreur ,pas le droit d'acceder a cette page</p>}
          />
        </Routes>
      </main>
      <ToastContainer />
      <Modal />
    </>
  );
}

export default App;
