import { DeleteConfirmation, UpdateConfirmation } from "./confirmationModal";
import { AddAgent } from "./addAgent";
import axios from "axios";
import {
  PhArrowFatLineUpLight,
  PhEye,
  PhNotePencil,
  PhTrash,
} from "../../GlobalComponents/Icons";
import { UpdateAgent } from "./updateAgent";
import { SearchDataAndAddNewDataSection } from "../../GlobalComponents/other";
import { useModal } from "../../contexts/modal";
import { SectionLoader } from "../../uikits/others";

export const AdministratorList = ({ props }) => {
  const { adminAndAgents, refetchDatas } = props;
  const { showModal, hideModal } = useModal();

  function toggleGrade(user) {
    let reqUrlEnd = "";
    if (user.grade == "Admin") {
      reqUrlEnd = "/admin/delete";
    } else {
      reqUrlEnd = "/admin";
    }
    axios
      .put("/admin" + reqUrlEnd, { id: user._id })
      .then((res) => window.location.reload())
      .catch((err) => console.log(err));
  }

  function deleteUser(user) {
    axios
      .post("/admin/delete", { id: user._id })
      .then((res) => {})
      .catch((err) => console.log(err))
      .finally(() => {
        hideModal();
        refetchDatas();
      });
  }
  return (
    <section className="administration-accounts">
      <SearchDataAndAddNewDataSection
        onSearch={null}
        isButton={
          <button
            onClick={() => {
              showModal(<AddAgent refetchDatas={refetchDatas} />);
            }}
          >
            Nouvel Agent
          </button>
        }
      />
      <table>
        <thead>
          <tr>
            <th>
              <h2>Nom</h2>
            </th>
            <th>
              <h2>Prenom</h2>
            </th>
            <th>
              <h2>Action</h2>
            </th>
          </tr>
        </thead>
        <tbody>
          {adminAndAgents.length ? (
            adminAndAgents.map((aaa, index) => (
              <tr key={"tr admin or agent nb" + index}>
                <td colSpan={1}>{aaa.nom}</td>
                <td colSpan={1}>{aaa.prenom}</td>
                <td colSpan={1}>
                  <i
                    className="viewIcon"
                    onClick={() => {
                      showModal(<UserAllInfo props={{ user: aaa }} />);
                    }}
                  >
                    {" "}
                    <PhEye />
                  </i>
                  {aaa.email != "fondateur@france-assist.fr" && (
                    <>
                      <i
                        className="updateIcon"
                        onClick={() => {
                          showModal(
                            <UpdateAgentInfos
                              props={{
                                agent: aaa,
                                refetchDatas,
                              }}
                            />
                          );
                        }}
                      >
                        <PhNotePencil />
                      </i>
                      <i
                        onClick={() => {
                          showModal(
                            <UpdateConfirmation
                              props={{
                                user: aaa,
                                toggleGrade,
                              }}
                            />
                          );
                        }}
                      >
                        <PhArrowFatLineUpLight />
                      </i>
                      <i
                        className="deleteIcon"
                        onClick={() => {
                          showModal(
                            <DeleteConfirmation
                              props={{ user: aaa, deleteUser }}
                            />
                          );
                        }}
                      >
                        <PhTrash />
                      </i>
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <SectionLoader />
          )}
        </tbody>
      </table>
    </section>
  );
};

const UserAllInfo = ({ props }) => {
  const { user } = props;

  return (
    <div>
      <p>
        <b>Nom :</b> {user.nom}
      </p>
      <p>
        <b>Prenom :</b> {user.prenom}
      </p>
      <p>
        <b>Email :</b> {user.email}
      </p>
      <p>
        <b>Grade :</b> {user.grade}
      </p>
      <p>
        <b>Telephone :</b> {user.telephone}
      </p>
      <p>
        <b>Adresse:</b> {user.address}
      </p>
      <p>
        <b>Lin Calendly:</b> {user.cal}
      </p>
      <p>
        <b>Code postal:</b> {user.codepostal}
      </p>
      <p>
        <b>En congé:</b> {user.isAvailable ? "Non" : "Oui"}
      </p>
    </div>
  );
};

function UpdateAgentInfos({ props }) {
  const { agent, refetchDatas } = props;
  return (
    <div>
      <UpdateAgent refetchDatas={refetchDatas} agent={agent} />
    </div>
  );
}
