import React from "react";
import { useState } from "react";
import axios from "axios";
import { UserFormFields } from "../userFormUI";
import { setCurrentClientToStore } from "../../Store/actions/clientActions";
import { Form, FormikProvider, useFormik } from "formik";
import { FormButton } from "../../uikits/button";
import { useModal } from "../../contexts/modal";
import { confirmUpdateWithPasswordsFields } from "../../utils/form/fields";
import FormikFieldController from "../../uikits/form";
import { ClientPasswordSchema } from "../../utils/form/validations";

export function UpdateClientModal({ props }) {
  const { client, dispatch } = props;
  const { hideModal } = useModal();
  const [creationStatus, setCreationStatus] = useState("");
  const formik = useFormik({
    initialValues: {
      nom: client.nom,
      prenom: client.prenom,
      codepostal: client.codepostal,
      telephone: client.telephone,
      email: client.email,
      address: client.address,
    },
    onSubmit: handleSubmit,
    validateOnMount: true,
  });
  const { isValid, isSubmitting } = formik;

  function handleSubmit(formValues) {
    let body = {
      nom: formValues.nom,
      prenom: formValues.prenom,
      telephone: formValues.telephone,
      email: formValues.email,
      codepostal: formValues.codepostal,
      clientId: client._id,
    };
    axios
      .put("/admin/update-client", body)
      .then((res) => {
        if (res.data.updateUser._id) {
          setCreationStatus(
            <AddOrUpdateStatus
              props={{
                type: "good",
                text: "Client modifié avec succes",
                other: null,
              }}
            />
          );
          dispatch(setCurrentClientToStore(res.data.updateUser));
        }
      })
      .then((err) => console.log(err))
      .finally(() => hideModal());
  }
  return (
    <div className="createClient">
      <h1>Modifier un client</h1>
      <FormikProvider value={formik}>
        <Form>
          <UserFormFields grade="Client" notPassword={true} />
          <FormButton
            text={isSubmitting ? "" : "Sauvegarder"}
            isValid={isSubmitting ? false : isValid}
          />
        </Form>
      </FormikProvider>
      {creationStatus}
    </div>
  );
}

export function UpdateClientPasswordModal({ props }) {
  const { clientInfos } = props;
  const { hideModal } = useModal();
  const [creationStatus, setCreationStatus] = useState("");
  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    onSubmit: handleSubmit,
    validateOnMount: true,
    validationSchema: ClientPasswordSchema,
  });

  const { isValid, isSubmitting } = formik;
  function handleSubmit(formValues) {
    let body = {
      id: clientInfos._id,
      password: formValues.password,
    };
    axios
      .post("/user/change-password", body)
      .then((res) => {
        if (res.data.message) {
          setCreationStatus(
            <AddOrUpdateStatus
              props={{
                type: "good",
                text: "Mot de passe du client modifié avec succes",
              }}
            />
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        hideModal();
      });
  }
  return (
    <div className="createClient">
      <h1>Modifier le mot de passe client</h1>
      <FormikProvider value={formik}>
        <Form>
          {confirmUpdateWithPasswordsFields.map((item, i) => (
            <FormikFieldController
              key={"new user pwd field nb" + i}
              {...item}
            />
          ))}
          <FormButton
            text="Enregistrer le nouveau mot de passe"
            isValid={isSubmitting ? false : isValid}
          />
        </Form>
      </FormikProvider>
      {creationStatus}
    </div>
  );
}

const AddOrUpdateStatus = ({ props }) => {
  const { type, text, other } = props;
  return (
    <div className="addOrUpdateStatus">
      <b className={type + "Status"}>{text}</b>
      {other && other}
    </div>
  );
};
