import * as Yup from "yup";

let nom = Yup.string().required("Champs requis");
let prenom = Yup.string().required("Champs requis");
let userName = Yup.string().required("Champs requis");

let telephone = Yup.string().required("Champs requis");

let email = Yup.string().email("Invalid email").required("Champs requis");

let address = Yup.string().required("Champs requis");
let codepostal = Yup.string().required("Champs requis");

let agentId = Yup.string().required("Champs requis");
let lien = Yup.string().required("Champs requis");
let grade = Yup.string().required("Champs requis");

let message = Yup.string().required("Champs requis");
let messageDate = Yup.string().required("Champs requis");

let stars = Yup.number().required("Champs requis");

let cardNumber = Yup.string()
  .max(16, "Trop court")
  .max(16, "Trop long")
  .required("Champs requis");
let code_derriere_carte = Yup.string()
  .max(3, "Trop court")
  .max(3, "Trop long")
  .required("Champs requis");
let carte_expiration_mois = Yup.string()
  .max(2, "Trop court")
  .max(2, "Trop long")
  .required("Champs requis");
let carte_expiration_année = Yup.string()
  .max(4, "Trop court")
  .max(4, "Trop long")
  .required("Champs requis");

let phone = Yup.string()
  .matches(/^(06|07)\d{6}$/, "Numéro de téléphone non valide")
  .required("Champs requis");
let pwd = Yup.string()
  .min(6, "Minimum 8 caractères : Mot de passe trop court!")
  .max(60, "Trop long");

let password_confirmation = Yup.string().oneOf(
  [Yup.ref("password"), null],
  "Les mots de passe doivent être conformes"
);
// .required("Champs requis")

export const PassSchema = Yup.object().shape({
  password: pwd.required("Champs requis"),
  password_confirmation: password_confirmation.required("Champs requis"),
});

export const LoginSchema = Yup.object().shape({
  email,
  password: pwd,
});

export const SignupSchema = Yup.object().shape({
  email,
  nom,
  prenom,
  telephone,
  codepostal,
  number: cardNumber,
  code_derriere_carte,
  carte_expiration_mois,
  carte_expiration_année,
  password: pwd.required("Champs requis"),
  password_confirmation: password_confirmation.required("Champs requis"),
});

export const UpdateGeneralInfoSchema = Yup.object().shape({
  address,
  password: pwd.notRequired(),
  password_confirmation: password_confirmation.notRequired(),
});

export const NewDocSchema = Yup.object().shape({
  title: Yup.string().required("Champs requis"),
  docFiles: Yup.array()
    .min(1, "Veuillez sélectionner au moins un fichier.")
    .required("Champs requis"),
});

export const NewClientSchema = Yup.object().shape({
  nom,
  prenom,
  email,
  telephone,
  codepostal,
  password: pwd.required("Champs requis"),
  password_confirmation: password_confirmation.required("Champs requis"),
});

export const NewClientByAdminSchema = Yup.object().shape({
  nom,
  prenom,
  email,
  telephone,
  codepostal,
  agentId,
  password: pwd.required("Champs requis"),
  password_confirmation: password_confirmation.required("Champs requis"),
});

export const NewAdminSchema = Yup.object().shape({
  nom,
  prenom,
  email,
  telephone,
  codepostal,
  grade,
  lien,
  //  address,
  password: pwd.required("Champs requis"),
  password_confirmation: password_confirmation.required("Champs requis"),
});

export const UpAdminSchema = Yup.object().shape({
  nom,
  prenom,
  email,
  telephone,
  codepostal,
  lien,
  address,
});

export const NewReviewSchema = Yup.object().shape({
  userName,
  message,
  messageDate,
  stars,
});

export const ClientPasswordSchema = Yup.object().shape({
  password: pwd.required("Champs requis"),
  password_confirmation: pwd.required("Champs requis"),
});
