import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { currentClientSelector } from "../../Store/selectors/clientSelector";

import { ClientInfosModal } from "./modals/clientInfosDetails";
import useRessource from "../../Hooks/client/useRessource";
import useDocument from "../../Hooks/client/useDocument";
import useTransaction from "../../Hooks/client/useTransaction";
import useNote from "../../Hooks/client/useNote";
import useEmail from "../../Hooks/client/useEmail";
import useSimulation from "../../Hooks/client/useSimulation";
import ClientInfosNavigationResult from "./helpers/clientNavigationResult";
import { setCurrentRefetcher } from "../../Store/actions/refetcherActions";
import { UpdateClientModal, UpdateClientPasswordModal } from "./update.js";
import usePasswords from "../../Hooks/client/usePasswords";
import axios from "axios";
import { ConfirmationMessage } from "../../GlobalComponents/Modal";
import {
  PhEye,
  PhLockKey,
  PhNotePencil,
  PhTrash,
  PhUserFill,
  PhUserGear,
} from "../../GlobalComponents/Icons";
import { clientNavigationRadio } from "../../Rawdata/forClient";
import { getAgent } from "../../utils/forClient";
import { useAuth } from "../../contexts/auth";
import { useModal } from "../../contexts/modal";
import { useFormik, Form, FormikProvider } from "formik";
import { SimpleInputWithFormik } from "../../uikits/form/simple";

export const ClientManagement = () => {
  const { user } = useAuth();
  const agentConnectedInfo = user;
  const { clientId, clientMenu } = useParams();
  const currentClient = useSelector(currentClientSelector);
  const { ressources } = useRessource(clientId);
  const { documents, refetchDocuments } = useDocument(clientId);
  const { emails, refetchEmails } = useEmail(clientId);
  const { simulations } = useSimulation(
    currentClient.email,
    currentClient.telephone
  );
  const { notes, refetchNotes } = useNote(clientId);
  const { transactions, refetchTransactions } = useTransaction(clientId);
  const { passwords, refetchPasswords } = usePasswords(clientId);
  const allAgents = user.otherAdmins.filter((item) => item.grade == "Agent");
  const { showModal, hideModal } = useModal();
  const dispatch = useDispatch();

  function confirmClientRemove() {
    showModal(
      <ConfirmationMessage
        props={{
          text: "Voulez vous vraiment supprimez le client et toute les données relative a son compte(simulations,emails,documents,notes,etc..) ?",
          functionAfterConfirm: deleteClient,
          param: null,
        }}
      />
    );
  }
  function deleteClient(param) {
    axios
      .delete("/admin/client/delete/" + clientId + "/" + agentConnectedInfo._id)
      .then((res) => (window.location = "/"))
      .catch((err) => alert("Une erreur a eu lieu pendant la suppression"));
  }

  const [navValue, setNavValue] = useState(clientMenu);
  const clientAgent = getAgent(allAgents, currentClient);
  const authed = agentConnectedInfo;
  return (
    <div className="clientManagement">
      <section className="cm-clientAndNavigation">
        <section className="ccan-ClientInfosAndAction">
          <PhUserFill />
          <article className="ccia-infos">
            <p>
              <b>Nom:</b>
              {currentClient.nom}
            </p>
            <p>
              <b>Prenoms:</b>
              {currentClient.prenom}
            </p>
            <p>
              <b>Email:</b>
              {currentClient.email}
            </p>
            {authed && authed.grade != "Agent" ? (
              <p>
                <b>Agent:</b>
                {clientAgent ? clientAgent.nom + " " + clientAgent.prenom : ""}
              </p>
            ) : (
              ""
            )}
          </article>
          <div className="ccia-actions">
            <span
              className="viewBtn"
              onClick={() => {
                showModal(
                  <ClientInfosModal props={{ clientInfos: currentClient }} />
                );
              }}
            >
              <PhEye />
            </span>
            {authed && authed.grade != "Agent" ? (
              <span
                className="updateBtn"
                onClick={() => {
                  showModal(
                    <UpdateClientAgent props={{ clientId, allAgents }} />
                  );
                }}
              >
                <PhNotePencil />
              </span>
            ) : (
              ""
            )}

            {
              <span
                className="updateBtn"
                onClick={() => {
                  showModal(
                    <UpdateClientModal
                      props={{
                        client: currentClient,
                      }}
                    />
                  );
                }}
              >
                <PhUserGear />
              </span>
            }

            <span
              className="updateBtn"
              onClick={() => {
                showModal(
                  <UpdateClientPasswordModal
                    props={{
                      clientInfos: currentClient,
                    }}
                  />
                );
              }}
            >
              <PhLockKey />
            </span>
            <span className="deleteBtn" onClick={() => confirmClientRemove()}>
              <PhTrash />
            </span>
          </div>
        </section>

        <div className="cn-navItem">
          {clientNavigationRadio.map((dhna, index) => (
            <label key={"dhna-k-" + index} htmlFor={"dhna-" + index}>
              <input
                type={"radio"}
                name="dhna"
                id={"dhna-" + index}
                value={dhna.value}
                onChange={() => {
                  if (dhna.value == "notes") {
                    dispatch(setCurrentRefetcher(refetchNotes));
                  } else if (dhna.value == "transactions") {
                    dispatch(setCurrentRefetcher(refetchTransactions));
                  } else if (dhna.value == "documents") {
                    dispatch(setCurrentRefetcher(refetchDocuments));
                  } else if (dhna.value == "emails") {
                    dispatch(setCurrentRefetcher(refetchEmails));
                  } else if (dhna.value == "passwords") {
                    dispatch(setCurrentRefetcher(refetchPasswords));
                  }
                  setNavValue(dhna.value);
                  document
                    .getElementById("clientDataNavResult")
                    .scrollIntoView();
                }}
                checked={navValue === dhna.value}
              />
              <section>
                <span>{dhna.icon}</span>
                <p>{dhna.label} </p>
              </section>
            </label>
          ))}
        </div>
      </section>
      <section className="cn-navResult" id="clientDataNavResult">
        {
          <ClientInfosNavigationResult
            props={{
              navValue,
              currentClient,
              agentConnectedInfo,
              ressources,
              documents,
              alertes: emails,
              notes,
              simulations,
              passwords,
              transactions,
            }}
          />
        }
      </section>
    </div>
  );
};

function UpdateClientAgent({ props }) {
  const [message, setMessage] = useState("");
  const { allAgents } = props;
  const formik = useFormik({
    initialValues: { agentId: "", clientId: props.clientId },
    onSubmit: handleSumbit,
  });

  async function handleSumbit(formValues) {
    let findAgent = allAgents.filter((item) => item._id == formValues.agentId);
    if (findAgent && findAgent.length > 0) {
      try {
        const upAgent = await axios.put("/user/update-agent-client", {
          agentId: formValues.agentId.trim(),
          clientId: formValues.clientId,
        });
        if (upAgent.data._id) {
          setMessage("Agent modifié avec success");
          setTimeout(() => window.location.reload(), 1000);
        }
      } catch (error) {
        console.log(error);
        alert("Erreur Survenue !");
      }
    } else {
      setMessage("Identifiant de l'agent incorrect");
    }
  }
  return (
    <div>
      <ul>
        {allAgents.length > 0 ? (
          <>
            {allAgents.map((alg, j) => (
              <li key={"agent info " + j}>
                Identifiant Agent {alg.nom + " " + alg.prenom} : {alg._id}
              </li>
            ))}
          </>
        ) : (
          "Chargement des agent....."
        )}
      </ul>
      <FormikProvider value={formik}>
        <Form>
          <SimpleInputWithFormik
            name="agentId"
            ph="Ex: 06c022d73dsnsd"
            label="Identifiant de l'agent"
          />
          <div className="formBtn">
            <button>Enregistrer</button>
          </div>
          <p>{message}</p>
        </Form>
      </FormikProvider>
    </div>
  );
}
