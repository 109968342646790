import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AddClientDataModal from "../modals/addClientData";
import { ClientRessources } from "./typeInfos/ressources";
import { ClientAlertes } from "./typeInfos/alertes";
import { ClientDocuments } from "./typeInfos/documents";
import { ClientNotes } from "./typeInfos/notes";
import { ClientTransactions } from "./typeInfos/transactions";
import { ClientSimulations } from "./typeInfos/simulations";
import { ClientPasswords } from "./typeInfos/passwords";
import { SearchDataAndAddNewDataSection } from "../../../GlobalComponents/other";
import { useModal } from "../../../contexts/modal";

function dispatchAddInfoBtnText(addedType) {
  if (addedType == "notes") {
    return "une note";
  } else if (addedType == "passwords") {
    return "un mot de passe";
  } else if (addedType == "emails") {
    return "un email";
  } else if (addedType == "transactions") {
    return "une transaction";
  }
}
const ClientInfosNavigationResult = ({ props }) => {
  const {
    navValue,
    currentClient,
    agentConnectedInfo,
    ressources,
    notes,
    documents,
    alertes,
    transactions,
    passwords,
    simulations,
  } = props;
  const { showModal, hideModal } = useModal();

  function handleDataSearch(event) {
    let inputValue = event.target.value.toLowerCase();
    let arrayCopy = [];
    let filterKey = "";
    let stateSetter = "";
    if (navValue == "notes") {
      arrayCopy = notes;
      filterKey = "title";
      stateSetter = setFN;
    } else if (navValue == "documents") {
      arrayCopy = documents;
      filterKey = "title";
      stateSetter = setFD;
    } else if (navValue == "emails") {
      arrayCopy = alertes;
      filterKey = "sujet";
      stateSetter = setFA;
    } else if (navValue == "transactions") {
      arrayCopy = transactions;
      filterKey = "titre";
      stateSetter = setFT;
    }

    let filter = arrayCopy.filter((doc) =>
      doc[filterKey].toLowerCase().includes(inputValue)
    );
    stateSetter(filter);
  }
  const [finalDocuments, setFD] = useState(documents);
  const [finalAlertes, setFA] = useState(alertes);
  const [finalNotes, setFN] = useState(notes);
  const [finalTransactions, setFT] = useState(transactions);
  useEffect(() => {
    setFD(documents);
    setFA(alertes);
    setFN(notes);
    setFT(transactions);
  }, [props]);
  const dispatch = useDispatch();

  function getClientNavResComponent() {
    if (navValue == "notes") {
      return <ClientNotes notes={finalNotes} />;
    } else if (navValue == "documents") {
      return <ClientDocuments documents={finalDocuments} />;
    } else if (navValue == "emails") {
      return <ClientAlertes alertes={finalAlertes} />;
    } else if (navValue == "transactions") {
      return <ClientTransactions transactions={finalTransactions} />;
    } else if (navValue == "simulations") {
      return <ClientSimulations simulations={simulations} />;
    } else if (navValue == "ressources") {
      return <ClientRessources ressources={ressources} />;
    } else if (navValue == "passwords") {
      return <ClientPasswords passwords={passwords} />;
    }
  }

  return (
    <>
      <h2>Liste des {navValue}</h2>
      {["notes", "documents", "emails", "transactions", "passwords"].includes(
        navValue
      ) && (
        <SearchDataAndAddNewDataSection
          onSearch={handleDataSearch}
          isButton={
            agentConnectedInfo &&
            (agentConnectedInfo.grade == "Agent" ||
              agentConnectedInfo.grade == "Sous-Administrateur") &&
            ["notes", "emails", "transactions", "passwords"].includes(
              navValue
            ) ? (
              <button
                onClick={() => {
                  showModal(
                    <AddClientDataModal
                      props={{
                        navValue,
                        currentClient,
                        agentConnectedInfo: agentConnectedInfo,
                        dispatch,
                      }}
                    />
                  );
                }}
              >
                {"Ajouter " + dispatchAddInfoBtnText(navValue)}
              </button>
            ) : (
              "searchFullWidth"
            )
          }
        />
      )}
      <div className="client-infosType">
        <section className="cit-liste">{getClientNavResComponent()}</section>
      </div>
    </>
  );
};

export default ClientInfosNavigationResult;
