import { useState, useEffect } from "react";
import axios from "axios";
import { PhasesOptions } from "../../../utils/form/others";
import {
  newClientGouvStructurePassFields,
  newMailFields,
  newNoteFields,
  newTransactionFields,
} from "../../../utils/form/fields";
import FormikFieldController from "../../../uikits/form";
export function ClientInfosTypeFields({ props }) {
  const { infosType } = props;
  const [aides, setAides] = useState([]);

  useEffect(() => {
    axios
      .get("https://dashboard.france-assist.fr/admin/aide/list")
      .then((res) => setAides(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {(() => {
        if (infosType === "emails") {
          return (
            <>
              {newMailFields.map((item, i) => (
                <FormikFieldController
                  {...item}
                  key={"new mail field nb" + i}
                />
              ))}
            </>
          );
        } else if (infosType == "notes") {
          return (
            <>
              {newNoteFields.map((item, i) => (
                <FormikFieldController
                  {...item}
                  key={"new note field nb" + i}
                />
              ))}
            </>
          );
        } else if (infosType == "transactions") {
          return (
            <>
              {newTransactionFields(PhasesOptions, aides).map((item, i) => (
                <FormikFieldController
                  {...item}
                  key={"new transactions field nb" + i}
                />
              ))}
            </>
          );
        } else if (infosType == "passwords") {
          return (
            <>
              {newClientGouvStructurePassFields.map((item, i) => (
                <FormikFieldController
                  {...item}
                  key={"new client gouv structure field nb" + i}
                />
              ))}
            </>
          );
        }
      })()}
    </>
  );
}
