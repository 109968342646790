import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../contexts/auth";

const useClientsSimulations = (
  defStatus,
  limit,
  page,
  keyWord,
  from,
  to,
  simulationStatus
) => {
  const [datas, setDatas] = useState([]);
  const [refetch, setRefecth] = useState(false);
  const [refetchCb, setRefecthCb] = useState(null);
  const [similationsLoading, setSL] = useState(true);
  const { user } = useAuth();
  const { grade } = user;

  useEffect(() => {
    let allDatas = null;
    let searchQuery = "";
    if (keyWord) {
      searchQuery += "&keyWord=" + keyWord;
    }
    if (from) {
      searchQuery += "&from=" + from;
    }
    if (to) {
      searchQuery += "&to=" + to;
    }
    if (simulationStatus || defStatus) {
      if (simulationStatus) {
        searchQuery += "&simulationStatus=" + simulationStatus;
      } else {
        searchQuery += "&simulationStatus=" + defStatus;
      }
    }

    // if (!defStatus) {
    //   searchQuery += "&simulationNeStatus=Lead Libre";
    // }
    // if (grade == 'Commercial') {
    //     searchQuery += '&commercialId=' + user._id
    // }
    let reqUrl =
      "/admin/simulations?limit=" +
      limit +
      "&currentPage=" +
      page +
      searchQuery;
    localStorage.setItem("lastSimulationReqUrl", reqUrl);

    axios
      .get(
        refetch && localStorage.getItem("lastSimulationReqUrl")
          ? localStorage.getItem("lastSimulationReqUrl")
          : reqUrl
      )
      .then((res) => {
        setDatas(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSL(false);
        if (refetchCb) {
          refetchCb();
          setRefecthCb(null);
        }
      });

    return setRefecth(false);
  }, [refetch]);

  const refetchDatas = (cb = null) => {
    setSL(true);
    setRefecth(true);
    if (cb && typeof cb === "function") {
      setRefecthCb(() => cb);
    }
  };

  return { clientSimulations: datas, similationsLoading, refetchDatas };
};

export default useClientsSimulations;
