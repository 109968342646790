import { useParams } from "react-router";
import { ClientManagement } from "../RoutesSubComponents/client/infos";
import CreationClient from "../RoutesSubComponents/client/add.js";
import ClientList from "../RoutesSubComponents/client/list.js";
import { withAuth } from "../hocs/withAuth";

const Client = () => {
  const { clientId, clientMenu, clientAction } = useParams();
  return (
    <div className="client">
      {(() => {
        if (clientId && clientMenu) {
          return <ClientManagement />;
        } else if (clientAction && !clientMenu) {
          return (
            <>
              {clientAction == "liste" ? (
                <>
                  <ClientList />
                </>
              ) : (
                <CreationClient />
              )}
            </>
          );
        }
      })()}
    </div>
  );
};

export default withAuth(Client);
