import React from "react";
import { PageTitle } from "../GlobalComponents/other";
import SimulationListFilterAndSearch from "../RoutesSubComponents/simulations/list";
import { useAuth } from "../contexts/auth";
import { withAuth } from "../hocs/withAuth";
import { useParams } from "react-router";

function Simulations() {
  const { user } = useAuth();
  const authedInfos = user;
  const { status } = useParams();

  return (
    <div className="simulations">
      <PageTitle title={"Simulations des clients"} />
      <SimulationListFilterAndSearch
        authedInfos={authedInfos}
        defStatus={status}
      />
    </div>
  );
}

export default withAuth(Simulations);
